import { staticURLs } from './staticURL';
import { urls, baseUrl } from './url';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  host: `${baseUrl}`,
  cannabisUrl: `https://cb.appening.xyz/`,
  tradeWasteUrl: `https://tw.appening.xyz/`,
  busineslicenseURL: `https://bl.appening.xyz/`,
  blUrl: `https://bl.appening.xyz/`,
  ap: `https://ap.appening.xyz/`,
  dogUrl: `https://dog.appening.xyz/`,
  frontendUrl:'https://licenseportal.us/',
  ceUrl:'https://coop-react.appening.xyz/auth/admin',
  image_url:'https://s3.amazonaws.com/licenseportal.us/',

  ...urls,
  ...staticURLs
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
